export const textPrompts = [
    {
      index: 0,
      name: 'chira',
    },
    {
      index: 1,
      name: 'lionel',
    },
    {
      index: 2,
      name: 'brutis',
    },
    {
      index: 3,
      name: 'chira',
    },
    {
      index: 4,
      name: 'brutis',
    },
    {
      index: 5,
      name: 'lionel',
    },
    {
      index: 6,
      name: 'chira',
    },
    {
      index: 7,
      name: 'lionel',
    },
    {
      index: 8,
      name: 'chira'
    },
  ];