<template>
  <div
    v-bind:style="backgroundImage"
    class="align-items-center d-flex flex-column text-black submit-main"
  >
    <div class="mt-3 w-75" style="height: 15%">
      <img :src="submitUsernameElements['logo']" alt="logo" class="h-100 w-100" />
    </div>
    <div class="align-items-center d-flex mt-4 w-100" style="height: 45%">
      <div class="h-75">
        <img :src="submitUsernameElements['lion03']" alt="" class="h-100" />
      </div>

      <div class="h-100 w-100">
        <div
          class="w-100 mt-4 chat-box"
        >
          <p class="fs-6 mx-0 my-0" style="color: #f25b60;">lionel:</p>
          <p class="chat-text" style="color: black;">{{ translationsFormatter('prompt_name_text') }}</p>
        </div>
        <div class="me-2 ms-2 mt-3" style="line-height: 1.3rem">
          <span class="fs-2"
            >{{ translationsFormatter('create_username_text') }}</span
          >
          <br />
          <span style="color: #1818ae">{{ translationsFormatter('select_character_text') }}</span>
        </div>
      </div>
    </div>
    <div class="align-items-center d-flex flex-column w-75">
      <input
        v-model="inputValue"
        type="text"
        id="userName"
        @input="checkInput"
        :placeholder="`${translationsFormatter('input_username_text')}`"
        class="border-0 fs-5 input-number p-2 rounded-3 text-center w-100"
        :style="{ height: '35%' }"
      />

      <div class="mt-3 w-100">
        <carousel
          ref="myCarousel"
          :items-to-show="3.95"
          :wrapAround="true"
          :transition="300"
          class="carousel"
        >
          <slide v-for="(imageUrl, iconName) in playerIconBlue" :key="iconName">
            <img :src="imageUrl" alt="Image" class="w-100" />
          </slide>
        </carousel>
      </div>

      <button
        @click="submitUsername"
        :disabled="isButtonDisabled"
        type="button"
        class="fs-4 mt-3 submit-btn text-light w-100"
      >
        {{ translationsFormatter('submit_button') }}
      </button>
    </div>
    <SpinnerUi v-if="showSpinner" />
  </div>
</template>

<script>
  import { ref } from 'vue';
  import 'vue3-carousel/dist/carousel.css';
  import { Carousel, Slide } from 'vue3-carousel';

  import {
    PlayerIconBlue,
    dynamicBg,
    staticImages,
  } from '../../../constants/imageUrlPath';
  import SpinnerUi from '../../SpinnerUi';
  import { createUser } from '../../../handlers/apis';

  export default {
    name: 'SubmitUsernameUi',
    components: {
      Carousel,
      Slide,
      SpinnerUi,
    },
    setup() {
      const myCarousel = ref(null);
      return {
        myCarousel,
      };
    },
    data() {
      return {
        inputValue: '',
        isButtonDisabled: true,
        playerIconBlue: PlayerIconBlue,
        showSpinner: false,
        submitUsernameElements: {
          logo: staticImages['logo'],
          lion03: staticImages['lion03'],
        },
      };
    },
    methods: {
      checkInput() {
        this.isButtonDisabled = this.inputValue.trim() === '';
      },
      async submitUsername() {
        this.showSpinner = true;
        try {
          const phone = this.$route.query.phone;
          const userName = this.inputValue.trim();
          const myCarousel = this.$refs.myCarousel;
          const selectedIcon = Object.keys(this.playerIconBlue)[
            myCarousel.data.currentSlide.value
          ];

          const user = await createUser({
            phoneNumber: phone,
            userName: userName,
            avatar: selectedIcon,
          });
          localStorage.setItem('userData', JSON.stringify(user));
          this.$router.push({ name: 'home', query: { phone } });
        } catch (error) {
          console.log(error.message);
        }
      },
    },
    computed: {
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
        };
      },
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
