import store from '@/store';
import { createUser } from '@/handlers/apis';

export async function userAuthenticate(to, from, next) {
  if (from.fullPath === '/') return next();
  if (store.state.user) return next();
  if (to.name === 'signup' || to.name === 'error' || to.name === 'termsConditions' ) return next();

  const urlParams = new URLSearchParams(window.location.search);
  const msisdn = urlParams.get('msisdn');

  if (msisdn) return await msisdnLoginAuth(msisdn, urlParams, next);
}

async function msisdnLoginAuth(msisdn, urlParams, next) {
  await setUserData(msisdn, urlParams);
  return redirectBasedOnUserStatus(store.state.user, msisdn, next);
}

async function setUserData(msisdn, urlParams) {
  const user = await createUser({
    phoneNumber: msisdn,
    online: false,
  });

  const userData = {
    channel: urlParams.get('channel'),
    extRef: urlParams.get('ext_ref'),
    msisdn: msisdn,
    result: urlParams.get('result'),
    resultId: urlParams.get('result_id'),
    subscriptionId: urlParams.get('subscription_id'),
  };

  localStorage.setItem('userData', JSON.stringify(user));
  store.commit('setUser', userData);
  store.commit('setMsisdn', msisdn);
}

function redirectBasedOnUserStatus(userData, msisdn, next) {
  return isUserResultValid(userData.result) ? next({ name: 'home', query: { msisdn } }) : next({ name: 'error' });
}

function isUserResultValid(userResult){
  return userResult === 'ACTIVE' || userResult === 'CONFIRMED';
}