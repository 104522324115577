<template>
  <!-- This serves all our pages using vue router -->
  <SpinnerUi v-if='showSpinner' />

  <router-view v-if='renderRoute && !assetsLoading' />

  <LoadingScreenUi
    v-if='assetsLoading'
    :navigateToSignup='false'
    @assets-loaded='onAssetsLoaded'
  />
</template>
<script>
  import { getUserByPhoneNumber } from './handlers/apis';
  import LoadingScreenUi from './components/LoadingScreenUi';
  import { setBrandConfig } from '@/functions/setBrandConfig';

  export default {
    name: 'App',
    components: {
      LoadingScreenUi,
    },
    data() {
      return {
        showSpinner: false,
        renderRoute: false,
        assetsLoading: false,
      };
    },
    methods: {
      onAssetsLoaded() {
        this.assetsLoading = false;
      },
    },
    async mounted() {
      if (window.location.pathname != '/') {
        this.assetsLoading = true;
      }
      const phoneNo = new URL(window.location.href).searchParams.get('phone');
      const userData = localStorage.getItem('userData');

      if (phoneNo && !userData) {
        try {
          this.showSpinner = true;
          const user = await getUserByPhoneNumber(phoneNo);
          localStorage.setItem('userData', JSON.stringify(user));
        } catch (error) {
          console.log(error);
        }
      }
      this.showSpinner = false;
      this.renderRoute = true;
    },
    async beforeMount() {
      setBrandConfig();
    },
  };
</script>
