<template>
  <OpponentSearchingUi v-if="!isOpponentFound" />

  <PowerupSelectionUi
    v-if="!matchStarted && isOpponentFound"
    :onPowerUpClicked="onPowerUpClicked"
    :powerups="powerups"
    :progressVal="matchStartProgress"
  />

  <SpinnerUi v-if="loading" />

  <div
    v-show="isOpponentFound && matchStarted"
    v-bind:style="backgroundImage"
    class="game-main"
  >
    <ScoreUi :room="room" />

    <PowerupsUi
      v-if="playerPowerups.length"
      :playerPowerups="playerPowerups"
      :opponentPowerups="opponentPowerups"
      @on-powerup-use="handlePowerupUse"
    />

    <WinnerAnimationUi
      :showButtons="true"
      :showWinner="showWinner"
      v-if="showWinner === true"
      :resetStateAndJoinNewGame="resetStateAndJoinNewGame"
    />
    <LoserAnimationUi
      :showLoser="showLoser"
      v-if="showLoser === true"
      :resetStateAndJoinNewGame="resetStateAndJoinNewGame"
    />

    <DrawAnimationUi
      :showButtons="true"
      :showDraw="showDraw"
      v-if="showDraw"
      :resetStateAndJoinNewGame="resetStateAndJoinNewGame"
    />

    <div :style="{ height: '52%' }">
      <!-- main canvas container -->
      <div id="canvas-container">
        <div v-show="showPotentialMove" class="grid-container position-absolute">
          <div
            v-for="cellNumber in gridCells"
            :key="cellNumber"
            :id="cellNumber"
            class="grid-item"
          ></div>
        </div>
        <div id="canvas-overlay"></div>
      </div>
      <TimerUi :turn="turn" :timerColor="timerColor" :progressVal="progressVal" />
    </div>
    <NavigationUi />
  </div>
</template>

<script>
  // pkgs imports
  import { get } from 'lodash';
  import { useToast } from 'vue-toastification';

  // classes imports
  import { Config } from '../../game/Config';
  import { Game } from '../../game/Game';
  import { MegamatchPixi } from '../../handlers/MegamatchPixi';
  import { SocketHandler } from '../../handlers/socket-handler';

  // components imports
  import DrawAnimationUi from '../DrawAnimationUi';
  import LoserAnimationUi from '../LoserAnimationUi';
  import NavigationUi from '../NavigationUi';
  import OpponentSearchingUi from '../OpponentSearchingUi';
  import PowerUpAnimation from '../PowerupAnimation';
  import PowerupSelectionUi from '../PowerupSelectionUi';
  import PowerupsUi from '../PowerupsUi';
  import ScoreUi from '../ScoreUi';
  import TimerUi from '../TimerUi';
  import WinnerAnimationUi from '../WinnerAnimationUi';
  import SpinnerUi from '../SpinnerUi';

  // constants imports
  import { getRandomNumber } from '../../common/funtions';
  import { dynamicBg, staticImages } from '../../constants/imageUrlPath';
  import { Events, POWERUPS_STATUS, POWER_UPS } from '../../enum/enum';
  import { mapState } from 'vuex';

  const pixiUi = true;

  let serverGameObject = {};
  let userData = {};
  let pauseTimer = false;
  let freezeTimeout;

  /** @type MegamatchPixi */
  let pixiObj = undefined;

  /** @type Game */
  let gameObject = undefined;

  /** @type SocketHandler */
  let socketHandler = undefined;

  export default {
    name: 'GameUi',
    components: {
      ScoreUi,
      PowerupsUi,
      TimerUi,
      NavigationUi,
      OpponentSearchingUi,
      WinnerAnimationUi,
      LoserAnimationUi,
      PowerupSelectionUi,
      DrawAnimationUi,
      PowerUpAnimation,
      SpinnerUi,
    },

    computed: {
      ...mapState(['user']),
      gridCells() {
        const numCells = 49;
        return Array.from({ length: numCells }, (_, index) => index + 1);
      },

      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[this.$DYNAMIC_BG] || dynamicBg[0]})`,
          '--blue-player-score-div-background': `url(${staticImages['bluePlayerTurn']})`,
          '--red-player-score-div-background': `url(${staticImages['redPLayerTurn']})`,
          '--powerup-container-background': `url(${staticImages['powerupContainer']})`,
          '--main-canvas-border': `url(${staticImages['gameBoard']})`,
        };
      },
    },

    methods: {
      onWindowResize() {
        pixiObj.configInstace = new Config(pixiUi);
      },
      canvasOverlayToggle(display) {
        document.getElementById('canvas-overlay').style.display = display;
      },

      toggleScoreBox(zIndexValue) {
        document.getElementById('score-box-blue').style.zIndex = zIndexValue;
      },

      handleOnConnect() {
        if (!this.gameOver) {
          setTimeout(() => {
            if (socketHandler) {
              socketHandler.socket.emit('game:join', {
                focus: true,
              });
            }
            this.loading = false;
          }, 1000);
        } else this.loading = false;
      },

      handleClientOnline() {
        if (!socketHandler) {
          socketHandler = new SocketHandler(this.user.msisdn);
          this.registerListeners();
        }
      },

      handleDisconnect(destorySocket) {
        this.loading = true;
        this.resetTimer();
        if (this.powerupTimer) {
          this.resetPowerUpSelectTimer();
        }
        if (destorySocket) {
          this.unRegisterListeners();
          socketHandler.socket.disconnect();
          socketHandler = null;
          gameObject.stateChanges.callbacks = {};
          gameObject = null;
        }
      },

      handleSyncGame(data) {
        const { name } = data.action;

        if (!name) {
          return;
        }

        switch (name) {
          case 'player:disconnected':
            if (socketHandler && document.hasFocus() && gameObject && !this.gameOver) {
              // emtting game:join for latest gameboard
              if (!gameObject.disabled) {
                /* reset game before sync the game to avoid multiple listeners */
                gameObject.stateChanges.callbacks = {};
                gameObject = null;
                socketHandler.socket.emit('game:join', {});
              } else {
                localStorage.setItem('opponentStatus', 'disconnected');
              }
            }
            break;

          case 'sync-powerup-page':
            if (socketHandler && document.hasFocus() && !this.gameOver) {
              socketHandler.socket.emit('powerup:selected', {
                action: {
                  name: 'powerup-selected',
                  powerups: this.powerups || [],
                },
              });
            }
            break;
        }
      },

      registerListeners() {
        if (!socketHandler) {
          return;
        }
        socketHandler.registerListener('connect', this.handleOnConnect);
        socketHandler.registerListener('disconnect', () => this.handleDisconnect(false));
        socketHandler.registerListener('game:start', this.onGameStart);
        socketHandler.registerListener(
          'server:update:timer-expire',
          this.onServerUpdateTimeExpire,
        );
        socketHandler.registerListener(
          'server:update:successfull-swap',
          this.onServerUpdateSuccessfullSwap,
        );
        socketHandler.registerListener(
          'server:update:start-timer',
          this.onServerUpdateStartTimer,
        );
        socketHandler.registerListener(
          'powerups:server:update',
          this.handleServerPowerupUse,
        );
        socketHandler.registerListener('select:powerups', this.onSelectPowerups);
        socketHandler.registerListener('error', this.toastError);
        socketHandler.registerListener('server:update:sync-game', this.handleSyncGame);
      },

      unRegisterListeners() {
        if (!socketHandler) {
          return;
        }
        socketHandler.unRegisterListener('connect', this.handleOnConnect);
        socketHandler.unRegisterListener('disconnect', () =>
          this.handleDisconnect(false),
        );
        socketHandler.unRegisterListener('game:start', this.onGameStart);
        socketHandler.unRegisterListener(
          'server:update:timer-expire',
          this.onServerUpdateTimeExpire,
        );
        socketHandler.unRegisterListener(
          'server:update:successfull-swap',
          this.onServerUpdateSuccessfullSwap,
        );
        socketHandler.unRegisterListener(
          'server:update:start-timer',
          this.onServerUpdateStartTimer,
        );
        socketHandler.unRegisterListener(
          'powerups:server:update',
          this.handleServerPowerupUse,
        );
        socketHandler.unRegisterListener('select:powerups', this.onSelectPowerups);
        socketHandler.unRegisterListener('error', this.toastError);
        socketHandler.unRegisterListener('server:update:sync-game', this.handleSyncGame);
        console.log('removed  listeners');
      },

      onSwapMatches(data) {
        // TODO: low priority: need to avoid swapByServer, as sometimes opponents user might be offline
        if (!data.swapByServer) {
          this.resetTimer();
        }

        // todo confirm this below condition if working fine.
        if (this.gameOver) {
          this.canvasOverlayToggle('block');
          return;
        }
        this.resetPotentialMovesInd();

        if (data && (!serverGameObject.serverSwap || this.isSecondPlayerBot)) {
          data = {
            ...data,
          };

          console.log('user:update:successfull-swap emitting');

          if (navigator.onLine && document.hasFocus() && !this.gameOver) {
            socketHandler.socket.emit('user:update:successfull-swap', {
              action: {
                ...data,
                name: 'user:update:successfull-swap',
                timestamp: new Date().toISOString(),
              },
            });
          }

          this.canvasOverlayToggle('block');
        }
        if (serverGameObject.serverSwap) {
          serverGameObject.serverSwap = false;
          // this.canvasOverlayToggle('none');
        }

        // gameobject will not give on-fall-down-over event when there are no successful matches.
        // In case of powerup `swapAnyTwo` non-matching pairs are allowed to swap so we need to emit
        // game:update:falldown-over from here to start the timer.
        if (data.matchesLength === 0) {
          console.log('game:update:falldown-over emmitting');
          if (navigator.onLine && document.hasFocus() && !this.gameOver) {
            setTimeout(() => {
              const opponentStatus = localStorage.getItem('opponentStatus');
              if (opponentStatus === 'disconnected') {
                gameObject.stateChanges.callbacks = {};
                gameObject = null;
                socketHandler.socket.emit('game:join', {});
                localStorage.removeItem('opponentStatus');
                return;
              }
              socketHandler.socket.emit('game:update:falldown-over', {
                action: { name: 'game:update:falldown-over' },
              });
            }, 1000);
          }
        }
      },

      onBoardFallDownOver() {
        console.log('game:update:falldown-over emmiting');
        if (navigator.onLine && document.hasFocus() && !this.gameOver) {
          const opponentStatus = localStorage.getItem('opponentStatus');
          if (opponentStatus === 'disconnected') {
            gameObject.stateChanges.callbacks = {};
            gameObject = null;
            socketHandler.socket.emit('game:join', {});
            localStorage.removeItem('opponentStatus');
            return;
          }
          socketHandler.socket.emit('game:update:falldown-over', {
            action: { name: 'game:update:falldown-over' },
          });
        }
      },

      makeCellsTransparent(cellIdsArr) {
        this.cellIdArray = cellIdsArr;
        for (let i = 0; i < cellIdsArr.length; i++) {
          const cellid = cellIdsArr[i];
          document.getElementById(cellid).style.zIndex = -1;
        }
      },

      resetPotentialMovesInd() {
        this.showPotentialMove = false;
        if (!gameObject) return;
        gameObject.showPotentialMove = false;
        if (this.cellIdArray.length) {
          for (let i = 0; i < this.cellIdArray.length; i++) {
            const cellid = this.cellIdArray[i];
            document.getElementById(cellid).style.zIndex = 0;
          }
        }
      },

      startTimer() {
        clearInterval(this.timer);
        console.log('strting interval');
        this.timer = setInterval(() => {
          if (!this.timeLeft) {
            this.timeLeft = 0;
            clearInterval(this.timer);
          }
          if (this.timeLeft >= 0) {
            if (!pauseTimer) {
              this.timeLeft -= 100;
              this.progressVal = (this.timeLeft / (15 * 1000)) * 100;
            }
          } else {
            clearInterval(this.timer);

            console.log('game:update:time-expire emitting');
            // gameObject.resetValidations();
            if (navigator.onLine && document.hasFocus() && !this.gameOver) {
              socketHandler.socket.emit('game:update:time-expire', {
                turn: userData._id,
                action: {
                  name: 'game:update:time-expire',
                },
              });
            }
          }
        }, 100);
      },

      endTimer() {
        clearInterval(this.timer);
      },

      getPlayersPowerups(players, turn) {
        const player = players.find(p => p.userId === turn);
        const opponent = players.find(p => p.userId !== turn);

        const powerupStatusArr = [
          Object.entries(player.powerUps).map(([key, value]) => ({
            name: key,
            status: value,
          })),
          Object.entries(opponent.powerUps).map(([key, value]) => ({
            name: key,
            status: value,
          })),
        ];

        return powerupStatusArr;
      },

      onGameStart(game) {
        if (gameObject && gameObject.disabled) {
          localStorage.setItem('gameObject', JSON.stringify(game));
          return;
        }

        if (game.gameOver) {
          this.gameOver = true;
          this.handleGameOver(game.players, true, game.result);
          this.loading = false;
          return;
        }

        if (game.players) {
          [this.playerPowerups, this.opponentPowerups] = this.getPlayersPowerups(
            game.players,
            userData._id,
          );
        }

        console.log('Game start recieved');
        this.isOpponentFound = true;
        this.matchStarted = true;
        const {
          gameBoard,
          nextTiles,
          isSecondPlayerBot,
          turnExpiry,
          freezeTime,
          timeLeft,
        } = game;
        this.isSecondPlayerBot = isSecondPlayerBot;

        this.timeLeft =
          Math.ceil(
            Math.abs(new Date(game.turnExpiry).getTime() - new Date().getTime()),
          ) || 0;

        console.log('time left for turn', this.timeLeft);

        let freeze = new Date(freezeTime).getTime() - new Date().getTime();

        serverGameObject = {
          ...game,
          serverSwap: false,
        };

        // this below function is used to change the UI appearance of the scores of user.
        this.room = this.rearrangeArrayByUserId(serverGameObject.players, userData._id);

        this.changeUiAppearance(serverGameObject.turn);

        if (serverGameObject.turn === userData._id) {
          this.canvasOverlayToggle('none');
        } else {
          this.canvasOverlayToggle('block');
        }

        //! TODO: do this in unmount
        if (gameObject) {
          gameObject.stateChanges.callbacks = {};
        }

        gameObject = new Game(gameBoard, nextTiles, pixiUi);
        pixiObj.app.stage.addChild(gameObject.container);

        gameObject.stateChanges.on(Events.SWAP_MATCH, this.onSwapMatches);
        gameObject.stateChanges.on(Events.CHANGES, this.onBoardFallDownOver);

        gameObject.currentTurnExipry = turnExpiry;

        this.startTimer();
        if (freeze > 0) {
          this.timeLeft = new Date(timeLeft).getTime();
          this.progressVal = (this.timeLeft / (15 * 1000)) * 100;
          pauseTimer = true;
          let timer = setTimeout(() => {
            pauseTimer = false;
            clearTimeout(timer);
          }, freeze);
        }

        this.loading = false;
      },

      handleGameOver(players, isGameOver, result) {
        if (isGameOver) {
          this.endTimer();

          this.room = this.rearrangeArrayByUserId(players, userData._id);
          if (gameObject) {
            gameObject.disabled = true;
          }
          this.gameOver = true;
          this.canvasOverlayToggle('block');
          if (result == userData._id) {
            this.showWinner = true;
          } else if (result === 'draw') {
            this.showDraw = true;
          } else {
            this.showLoser = true;
          }
        }
      },

      updateTurn(playersArr, turn, timeLeft) {
        // const players = get(data, "players", []);
        this.room = this.rearrangeArrayByUserId(playersArr, userData._id);

        this.changeUiAppearance(turn);

        this.timeLeft =
          Math.ceil(Math.abs(new Date(timeLeft).getTime() - new Date().getTime())) || 0;

        this.startTimer();
      },

      onServerUpdateTimeExpire(data) {
        if (!data || !data.action) {
          return;
        }

        console.log('server:update:timer-expire received');

        clearTimeout(freezeTimeout);
        pauseTimer = false;

        const { action, turn, gameOver, timeLeft, result } = data;

        this.resetPotentialMovesInd();

        /* if any powerup is selected, we will set as used */
        // players.forEach((p, i) => {
        //   /* if timer expires with selected powerups then we considering as used */
        //   Object.keys(p.powerUps).forEach(pwrKey => {
        //     if (p.powerUps[pwrKey] === POWERUPS_STATUS.SELECTED) {
        //       players[i].powerUps[pwrKey] = POWERUPS_STATUS.USED;
        //     }
        //   });
        // });
        /* updating status of powerup buttons */
        // [this.playerPowerups, this.opponentPowerups] = this.getPlayersPowerups(
        //   players,
        //   userData._id,
        // );

        /* updating status of players powerup buttons if any powerup was selected */
        this.updatePowerupsOnTurnSwitch();

        gameObject?.resetValidations();

        if (action?.timer) {
          if (userData._id !== turn) {
            this.canvasOverlayToggle('block');
          } else {
            this.canvasOverlayToggle('none');
          }
        }

        this.updateTurn(get(data, 'players', []), turn, timeLeft);

        serverGameObject.turn = data.turn;
        if (
          this.isSecondPlayerBot &&
          turn === 'undefined' &&
          action?.name === 'bot-swap'
        ) {
          /* allowing bot to play randomly between 4 to 5 seconds */
          this.botplay(data);
        }
        gameObject.currentTurnExipry = timeLeft;
        this.handleGameOver(data.players, gameOver, result);
      },

      botplay(data) {
        const { shuffledGame, powerUpSelected } = data;

        if (shuffledGame) {
          gameObject.playPowerupAnimation(powerUpSelected);

          setTimeout(
            () => this.updateBoard(shuffledGame.gameBoard, shuffledGame.nextTiles),
            700,
          );
        }

        this.opponentPowerups = this.opponentPowerups.map(pp => {
          if (Object.values(pp).includes(data.powerUpSelected)) {
            /* setting selected status for this 3 powerups */
            if (
              [POWER_UPS.BURST_FOUR, POWER_UPS.DOUBLE_POINTS].includes(powerUpSelected)
            ) {
              pp.status = POWERUPS_STATUS.SELECTED;
            } else if (powerUpSelected === POWER_UPS.RESHUFFLE) {
              pp.status = POWERUPS_STATUS.USED;
            }
          }
          return pp;
        });

        if (powerUpSelected === POWER_UPS.DOUBLE_POINTS) {
          gameObject.playPowerupAnimation(powerUpSelected);
        }

        setTimeout(() => {
          const { destTile, sourceTile } = data.action;
          if (powerUpSelected === POWER_UPS.BURST_FOUR) {
            const randomRow = Math.floor(Math.random() * 6);
            const randomCol = Math.floor(Math.random() * 6);
            const tile1 = { row: randomRow, col: randomCol };
            const tile2 = { row: randomRow, col: randomCol + 1 };
            const tile3 = { row: randomRow + 1, col: randomCol + 1 };
            const tile4 = { row: randomRow + 1, col: randomCol };

            gameObject.destroyFour(tile1, tile2, tile3, tile4, true);

            data = {
              ...data,
              tile1,
              tile2,
              tile3,
              tile4,
            };

            socketHandler.socket.emit('user:update:successfull-swap', {
              action: {
                ...data,
                name: 'user:update:successfull-swap',
                timestamp: new Date().toISOString(),
              },
            });
          } else {
            this.swapBoardByIndex(
              sourceTile.row,
              sourceTile.col,
              destTile.row,
              destTile.col,
            );
          }

          serverGameObject.serverSwap = true;
          serverGameObject.serverFalldownOver = true;
        }, getRandomNumber(3, 5) * 1000);
      },

      onServerUpdateSuccessfullSwap(data) {
        if (!data || !data.action) {
          return;
        }
        console.log('server:update:successfull-swap received');

        clearTimeout(freezeTimeout);
        pauseTimer = false;

        const { action, turn, gameOver, timeLeft, result, powerupWasSelected } = data;

        this.updateTurn(get(data, 'players', []), turn, timeLeft);

        if (
          !action?.timer &&
          userData._id === turn &&
          !action?.alreadySwapped &&
          !action?.swap
        ) {
          serverGameObject.turn = turn;

          // if (action.destroyFourValidation) {
          if (powerupWasSelected === POWER_UPS.BURST_FOUR) {
            gameObject.destroyFour(
              action.tile1,
              action.tile2,
              action.tile3,
              action.tile4,
              true,
            );
          } else {
            const { destTile, sourceTile } = action;
            console.log({ destTile, sourceTile });
            this.resetTimer();
            // if (swapTwoValidation && turn === userData._id) {
            // console.log({ powerupWasSelected });
            if (powerupWasSelected === POWER_UPS.SWAP_TWO) {
              gameObject.swapTwoValidation = true;
              const tile1 = gameObject.board.getField(
                sourceTile.row,
                sourceTile.col,
              ).tile;
              if (tile1) {
                tile1.swapTwoAnimation();
              }
              const tile2 = gameObject.board.getField(destTile.row, destTile.col).tile;
              if (tile2) {
                tile2.swapTwoAnimation();
              }
            }
            this.swapBoardByIndex(
              sourceTile.row,
              sourceTile.col,
              destTile.row,
              destTile.col,
              true,
            );
            serverGameObject.serverSwap = true;
          }
          serverGameObject.serverFalldownOver = true;
        }

        gameObject.currentTurnExipry = timeLeft;
        this.handleGameOver(data.players, gameOver, result);

        /* updating status of players powerup buttons if any powerup was selected */
        this.updatePowerupsOnTurnSwitch();

        /* resetting it on delay becoz swapBoardByIndex operation takes little time */
        setTimeout(() => {
          gameObject?.resetValidations();
        }, 500);
      },

      onServerUpdateStartTimer(data) {
        console.log('onServerUpdateStartTimer received ', data.action.name);

        if (!data || !data.action) {
          return;
        }

        clearTimeout(freezeTimeout);
        pauseTimer = false;

        const { action, turn, gameOver, timeLeft, result } = data;

        if (turn.toString() === userData._id.toString()) {
          this.canvasOverlayToggle('none');
        }

        if (action?.name === 'start-timer') {
          this.timeLeft =
            Math.ceil(
              Math.abs(new Date(data.timeLeft).getTime() - new Date().getTime()),
            ) || 0;

          this.startTimer();
          serverGameObject.turn = data.turn;

          if (data.turn === 'undefined') {
            this.canvasOverlayToggle('block');
            /* allowing bot to play randomly between 4 to 5 seconds */
            this.botplay(data);
          }
          return;
        }

        // todo: check if this below lines of code are working any time or else remove these lines!
        gameObject.currentTurnExipry = timeLeft;

        this.updateTurn(get(data, 'players', []), turn, timeLeft);

        if (gameOver && result) {
          this.handleGameOver(data.players, gameOver, result);
        }
      },

      swapBoardByIndex(row1, col1, row2, col2, swapByServer = false) {
        if (gameObject) {
          gameObject.swap(
            gameObject.board.getField(row1, col1).tile,
            gameObject.board.getField(row2, col2).tile,
            undefined,
            swapByServer,
          );
        }
      },

      changeUiAppearance(turn) {
        if (userData._id === turn) {
          this.turn = `${this.translationsFormatter('your_turn_loader_text')}`;
          this.scoreBoxToggle = true;
          this.timerColor = 'bg-info';
        } else {
          this.turn = `${this.translationsFormatter('opponent_turn_loader_text')}`;
          this.scoreBoxToggle = false;
          this.timerColor = 'bg-danger';
        }
        this.progressVal = 100;
        let scoreBox = this.scoreBoxToggle ? 'score-box-blue' : 'score-box-red';
        let animationClass = this.scoreBoxToggle
          ? 'scoreBlue-animation'
          : 'scoreRed-animation';

        let scoreBoxElement = document.getElementById(scoreBox);
        scoreBoxElement.classList.add(animationClass);

        scoreBoxElement.addEventListener(
          'animationend',
          () => {
            scoreBoxElement.classList.remove(animationClass);
          },
          { once: true },
        );
      },

      rearrangeArrayByUserId(array, screenUserId) {
        const index = array.findIndex(item => item.userId === screenUserId);
        if (index !== -1) {
          const screenUserItem = array.splice(index, 1)[0];
          array.unshift(screenUserItem);
        }
        return array;
      },

      resetStateAndJoinNewGame() {
        if (navigator.onLine && document.hasFocus()) {
          this.room = [];
          this.gameOver = false;
          this.scoreBoxToggle = false;
          this.turn = '';
          this.timeLeft = 15 * 1000;
          this.progressVal = 100;
          this.timerColor = 'bg-info';
          this.showWinner = false;
          this.showLoser = false;
          this.showDraw = false;
          this.isOpponentFound = false;

          console.log('game:join emmiting resetStateAndJoinNewGame');
          socketHandler.socket.emit('game:join', {});
          this.matchStarted = false;
          this.powerups = [];
          this.playerPowerups = [];
          this.opponentPowerups = [];
          gameObject?.resetValidations();
          this.resetPotentialMovesInd();
        }
      },

      onSelectPowerups(data) {
        console.log('select:powerups received');
        this.isOpponentFound = true;
        this.matchStartTimer = Math.ceil(
          Math.abs(new Date(data.powerUpSelectExpiry).getTime() - new Date().getTime()),
        );
        this.startPowerupSelectTimer();
      },

      onPowerUpClicked(e) {
        const id = e.target.id;
        if (this.powerups.length < 2 && !this.powerups.includes(id)) {
          this.powerups.push(id);
          document.getElementById(id).style.opacity = 0.5;
        } else if (this.powerups.includes(id)) {
          const index = this.powerups.indexOf(id);
          this.powerups.splice(index, 1);
          document.getElementById(id).style.opacity = 1;
        }
      },

      startPowerupSelectTimer() {
        clearInterval(this.powerupTimer);

        this.powerupTimer = setInterval(() => {
          if (this.matchStartTimer > 0) {
            this.matchStartTimer -= 100;
            this.matchStartProgress = (this.matchStartTimer / 10000) * 100;
          } else {
            clearInterval(this.powerupTimer);
            console.log('powerup:selected emmiting');
            if (navigator.onLine && document.hasFocus() && !this.gameOver) {
              socketHandler.socket.emit('powerup:selected', {
                action: {
                  name: 'powerup-selected',
                  powerups: this.powerups,
                },
              });
            }
          }
        }, 100);
      },

      resetPowerUpSelectTimer() {
        clearInterval(this.powerupTimer);
        this.matchStartTimer = 10000;
        this.matchStartProgress = 100;
      },

      toastError(data) {
        console.log('error recieved');
        // if (['Turn Expiry is Null!'].includes(data.errorMessage)) return;
        this.toast.error(data.errorMessage, { position: 'top-center' });
        if (
          data.errorMessage === "Can't swap, your turn already changed!" ||
          data.errorMessage === "Can't swap" ||
          data.errorMessage === 'Swap not successfull!'
        ) {
          this.timeLeft =
            Math.ceil(
              Math.abs(new Date(data.turnExpiry).getTime() - new Date().getTime()),
            ) || 0;
          this.updateBoard(data.gameBoard, data.nextTiles);
          this.startTimer();
        }
      },

      updateBoard(gameBoard, nextTiles) {
        /* resseting old listeners */
        gameObject.stateChanges.callbacks = {};

        const oldExpiry = gameObject.currentTurnExipry;

        gameObject.updateBoard(gameBoard, nextTiles);

        gameObject.currentTurnExipry = oldExpiry;

        gameObject.stateChanges.on(Events.SWAP_MATCH, this.onSwapMatches);
        gameObject.stateChanges.on(Events.CHANGES, this.onBoardFallDownOver);
      },

      resetTimer() {
        this.endTimer();
        this.timeLeft = 15 * 1000;
        this.progressVal = 100;
      },

      handlePowerupUse(powerupName) {
        // console.log(
        //   'calling ',
        //   'handlePowerupUse',
        //   { powerupName },
        //   serverGameObject.turn,
        //   userData._id,
        //   gameObject.disabled,
        // );

        if (
          (!gameObject.disabled && serverGameObject.turn !== userData._id) ||
          serverGameObject.turn !== userData._id ||
          serverGameObject.turn === 'undefined' ||
          gameObject.disabled ||
          !powerupName
        ) {
          return;
        }

        if (document.getElementById('canvas-overlay').style.display === 'block') {
          return;
        }

        /* switch case for hanging edge case */
        switch (powerupName) {
          case POWER_UPS.BURST_FOUR: {
            /* we are not allowing burst 4 powerup if user already selected swap any 2  */
            if (gameObject.swapTwoValidation) {
              return;
            }
            break;
          }
          /* we are not allowing swap any 2 powerup if user already selected burst 4 powerup */
          case POWER_UPS.SWAP_TWO: {
            if (gameObject.destroyFourValidation) {
              return;
            }
            break;
          }
          default:
            break;
        }

        if (navigator.onLine && document.hasFocus() && !this.gameOver) {
          socketHandler.socket.emit('powerUps:clientUpdate', {
            timeLeft: this.timeLeft,
            action: {
              name: powerupName,
              timestamp: new Date().toISOString(),
            },
          });
        }

        /* update powerup button state */
        this.playerPowerups = this.playerPowerups.map(pp => {
          if (Object.values(pp).includes(powerupName)) {
            /* setting selected status for this 3 powerups */
            if (
              [
                POWER_UPS.SWAP_TWO,
                POWER_UPS.BURST_FOUR,
                POWER_UPS.DOUBLE_POINTS,
              ].includes(powerupName)
            ) {
              pp.status = POWERUPS_STATUS.SELECTED;
            } else {
              pp.status = POWERUPS_STATUS.USED;
            }
          }
          return pp;
        });
      },

      async handleServerPowerupUse(powerupData) {
        const powerupName = powerupData.action.name;

        /* when opponent plays powerups, we updating opponents opwerup states */
        if (powerupData.turn !== userData._id && !serverGameObject.isSecondPlayerBot) {
          this.opponentPowerups = this.opponentPowerups.map(pp => {
            if (Object.values(pp).includes(powerupName)) {
              pp.status = powerupData.powerupStatus || POWERUPS_STATUS.USED;
            }
            return pp;
          });
        }

        /* here we play animation */
        if (![POWER_UPS.SWAP_TWO, POWER_UPS.BURST_FOUR].includes(powerupName)) {
          await gameObject.playPowerupAnimation(powerupName);
        }

        switch (powerupName) {
          case POWER_UPS.SWAP_TWO: {
            if (
              powerupData.turn.toString() === userData._id.toString() &&
              powerupData.powerupStatus === POWERUPS_STATUS.SELECTED
              // powerupData.swapTwo === POWERUPS_STATUS.NOT_USED
            ) {
              gameObject.swapTwoValidation = true;
            }
            break;
          }

          case POWER_UPS.BURST_FOUR: {
            if (
              powerupData.turn.toString() === userData._id.toString() &&
              powerupData.powerupStatus === POWERUPS_STATUS.SELECTED
              // powerupData.destroyFour === POWERUPS_STATUS.NOT_USED
            ) {
              gameObject.destroyFourValidation = true;
            }
            break;
          }

          case POWER_UPS.FREEZE: {
            clearTimeout(freezeTimeout);
            const timeToFreeze = Math.abs(
              new Date(powerupData.freezeTime).getTime() - new Date().getTime(),
            );

            pauseTimer = true;

            gameObject.currentTurnExipry = powerupData.turnExpiry;

            freezeTimeout = setTimeout(function () {
              pauseTimer = false;
              clearTimeout(freezeTimeout);
            }, timeToFreeze);
            break;
          }

          case POWER_UPS.RESHUFFLE: {
            // doing shuffile after 700ms, we should update boad on animationCompleted
            setTimeout(
              () => this.updateBoard(powerupData.gameBoard, powerupData.nextTiles),
              700,
            );

            if (powerupData.turn === userData._id) {
              this.resetPotentialMovesInd();
            }
            break;
          }

          case POWER_UPS.POTENTIAL_MOVES: {
            const { coordinatePairs, turn } = powerupData;

            if (coordinatePairs) {
              if (turn.toString() === userData._id.toString()) {
                this.showPotentialMove = true;
                const { tile1, tile2 } = coordinatePairs;

                const cell1 = tile1.row * 7 + (tile1.col + 1);
                const cell2 = tile2.row * 7 + (tile2.col + 1);

                this.makeCellsTransparent([cell1, cell2]);
                if (!gameObject) return;
                gameObject.showPotentialMove = this.showPotentialMove;
              }
            }
            break;
          }

          default:
            break;
        }
      },

      updatePowerupsOnTurnSwitch() {
        /* if timer expires with selected powerups then we considering as used on timer expire or on successfull swap */
        this.opponentPowerups = this.opponentPowerups.map(op => {
          if (op.status === POWERUPS_STATUS.SELECTED) op.status = POWERUPS_STATUS.USED;
          return op;
        });

        this.playerPowerups = this.playerPowerups.map(pp => {
          if (pp.status === POWERUPS_STATUS.SELECTED) pp.status = POWERUPS_STATUS.USED;
          return pp;
        });
      },
    },

    data() {
      // all states
      return {
        /**
         * Room containing two players
         * @type Array
         * */
        room: [],
        /** @type Array */
        cellIdArray: [],
        /** @type Boolean */
        gameOver: false,

        /**
         * changes when turn changes
         * @type boolean
         */
        scoreBoxToggle: false,

        /**
         * To be Displayed on the screen. If its your turn the value is set to 'Your Turn' else 'Opponent's turn'
         * @type String
         */
        turn: '',

        /** Time left for the user to play his/her turn
         * @type Number
         */
        timeLeft: 15,

        /** Progress bar percentage
         * @type Number
         */
        progressVal: 100,

        /**
         * Bootstrap css property to change progress bar color according to the player turn. Values = bg-info / bg-danger
         * @type String
         */
        timerColor: 'bg-info',

        /**
         * state used to show the winner animation
         * @type Boolean
         */
        showWinner: false,

        /**
         * state used to show the loser animation
         * @type Boolean
         */
        showLoser: false,

        showDraw: false,

        isOpponentFound: false,

        /**Flag for displaying powerup selection window
         * @type Boolean
         */
        matchStarted: false,

        /**Timer to select powerups
         * @type Number
         */
        matchStartTimer: 10000,

        /**Timer to select powerups
         * @type Number
         */
        matchStartProgress: 100,

        /**User selected Powerups
         * @type Array
         */
        powerups: [],

        selectedPowerUps: {},

        /** defines if the second player is a bot or not
         * @type boolean
         */
        isSecondPlayerBot: false,

        sourceTile: {},

        destTile: {},

        showPotentialMove: false,

        /* powerup states */
        /** @type Array<{name: string, status: boolean}> */
        playerPowerups: [],
        /** @type Array<{name: string, status: boolean}> */
        opponentPowerups: [],

        loading: false,
      };
    },

    watch: {
      scoreBoxToggle(val) {
        if (val) {
          this.toggleScoreBox(1);
        } else {
          this.toggleScoreBox(0);
        }
      },

      room(room) {
        for (let i = 0; i < room.length; i++) {
          const score = room[i].score.toString();
          if (score.length > 3) {
            if (i === 0) {
              document.getElementById('score-one').style.fontSize = '1rem';
            } else {
              document.getElementById('score-two').style.fontSize = '1rem';
            }
          }
        }
      },
    },

    setup() {
      const toast = useToast();
      return { toast };
    },

    async mounted() {
      userData = JSON.parse(localStorage.getItem('userData'));
      pixiObj = new MegamatchPixi(pixiUi);

      await pixiObj.init();

      window.addEventListener('resize', this.onWindowResize);
      window.addEventListener('online', this.handleClientOnline);
      window.addEventListener('offline', () => this.handleDisconnect(true));
      window.addEventListener('blur', () => this.handleDisconnect(true));
      window.addEventListener('focus', this.handleClientOnline);

      socketHandler = new SocketHandler(this.user.msisdn);

      this.registerListeners();
    },

    unmounted() {
      this.unRegisterListeners();
      window.removeEventListener('blur', () => this.handleDisconnect(true));
      window.removeEventListener('focus', this.handleClientOnline);
      window.removeEventListener('resize', this.onWindowResize);
      window.removeEventListener('online', this.handleClientOnline);
      window.removeEventListener('offline', () => this.handleDisconnect(true));
      if (pixiObj.app && socketHandler) {
        pixiObj.app.destroy();
        socketHandler.disconnectConnection();
      }
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
