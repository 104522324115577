<template>
  <WinnerAnimationUi :showButtons="false" />

  <div
    class="align-items-center d-flex flex-column h-25 w-100 position-absolute z-2"
    style="bottom: 7rem"
  >
    <div class="d-flex h-100 w-100">
      <div class="h-100">
        <img src="/assets/Tutorial/cheata-02.png" alt="" class="h-100 z-1" />
      </div>
      <div
          class="w-100 mt-4 chat-box"
        >
          <p class="fs-6 mx-0 my-0" style="color: #9f875b;">lionel:</p>
          <p class="chat-text" style="color: black;">{{ translationsFormatter('beat_brutis_text') }}</p>
        </div>
    </div>
    <button
      @click="continueBtn"
      type="button"
      class="align-items-center continue-btn d-flex fs-4 justify-content-center p-3 text-light w-50"
    >
      {{ translationsFormatter('continue_button') }}
    </button>
  </div>
</template>

<script>
  import WinnerAnimationUi from '../../WinnerAnimationUi';

  export default {
    name: 'UserWins',
    components: {
      WinnerAnimationUi,
    },
    methods: {
      continueBtn() {
        const phone = this.$route.query.phone;
        this.$router.push({ name: 'submitusername', query: { phone } });
      },
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
