<template>
  <div id="loser-container" class="loser-container">
    <div id="loser-anim-container" class="loser-anim"></div>
    <button
      @click="resetStateAndJoinNewGame"
      type="button"
      class="confirmBtn fs-4 text-light mt-5 top-50 position-absolute"
    >
      {{ translationsFormatter('play_again_button') }}
    </button>
    <button
      @click="navigateToHomePage"
      type="button"
      class="backBtn fs-4 text-light mt-4 position-absolute"
    >
      {{ translationsFormatter('home_button') }}
    </button>
  </div>
</template>

<script setup>
  import { defineProps } from 'vue';
  defineProps({
    resetStateAndJoinNewGame: Function,
    showLoser: {
      type: Boolean,
    },
  });
</script>

<script>
  import { Config } from '../../game/Config';
  import * as PIXI from 'pixi.js';
  // eslint-disable-next-line no-unused-vars
  import { Spritesheet } from 'pixi.js';
  import { animationPaths } from '../../assetsPath';

  let animation;
  let configInstace;
  let app;

  export default {
    name: 'LoserAnimationUi',
    data() {
      return {};
    },
    async mounted() {
      app = new PIXI.Application({ backgroundAlpha: 0 });

      configInstace = new Config(true);
      app.view.width = configInstace.uiConstants.width * 0.75;
      app.view.height = configInstace.uiConstants.width * 0.75;

      document.getElementById('loser-anim-container').appendChild(app.view);

      /**
       * @type Spritesheet
       */
      let spriteSheet = PIXI.Cache.get(animationPaths[4]);

      if (!spriteSheet) {
        spriteSheet = await PIXI.Assets.load(animationPaths[4]);
      }

      animation = PIXI.AnimatedSprite.fromFrames(
        spriteSheet.data.animations['loser-animation'],
      );

      animation.position.x = 0;
      animation.position.y = 0;
      animation.loop = false;
      animation.width = configInstace.uiConstants.width * 0.75;
      animation.height = configInstace.uiConstants.width * 0.75;
      animation.animationSpeed = 1 / 2;

      if (app.stage) {
        app.stage.addChild(animation);
        animation.play();
      }

      window.addEventListener('resize', this.onWindowResize);
    },
    unmounted() {
      window.removeEventListener('resize', this.onWindowResize);
    },
    components: {},
    methods: {
      onWindowResize() {
        configInstace = new Config(true);
        animation.width = configInstace.uiConstants.width * 0.75;
        animation.height = configInstace.uiConstants.width * 0.75;

        app.view.width = configInstace.uiConstants.width * 0.75;
        app.view.height = configInstace.uiConstants.width * 0.75;
      },
      navigateToOpponentSearch() {
        const phone = this.$route.query.phone;
        this.$router.push({ name: 'game', query: { phone } });
      },
      navigateToHomePage() {
        const phone = this.$route.query.phone;
        this.$router.push({ name: 'home', query: { phone } });
      },
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
