import { createApp } from 'vue';
import App from './App.vue';
import VueNumber from 'vue-number-animation';
import Toast from 'vue-toastification';
import store from './store';
import 'vue-toastification/dist/index.css';
import 'bootstrap/dist/css/bootstrap.css';
import router from './router/index';
import {translationsFormatter} from "@/functions/translationsFormatter";

const app = createApp(App);

app.config.globalProperties = {
  translationsFormatter,
};

app.use(router);
app.use(VueNumber);
app.use(Toast);
app.use(store);

app.mixin({
  mounted() {
    this.setAppHeight();
    window.addEventListener('resize', this.setAppHeight);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setAppHeight);
  },
  methods: {
    setAppHeight() {
      const appDiv = document.getElementById('app');
      if (appDiv) {
        appDiv.style.height = window.innerHeight + 'px';
      }
    },
  },
});

app.config.warnHandler = function() {
  return null;
};

app.mount('#app');
