<template>
  <div v-bind:style="backgroundImage" class="justify-content-center loading-main">
    <div class="w-100" :style="{ height: '30%' }">
      <img
        :src="loadingScreenElements['loadingScreenLogo']"
        alt="logo"
        class="h-100 w-100"
      />
    </div>
    <div class="align-items-center d-flex flex-column h-25 mt-5 w-75">
      <span class="fs-2"> {{ translationsFormatter('loading_text') }}...</span>
      <div class="progress w-100 loading-div">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{ width: `${progressValue}%` }"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
    </div>
  </div>
  <PreloadStaticImages
    @images-loaded="onImageLoaded"
    @loaded-images="onImageLoadHandler"
  />
</template>

<script>
  import * as PIXI from 'pixi.js';

  import preload from '../../Loader';
  import PreloadStaticImages from '../PreloadStaticImages';
  import { dynamicBg, staticImages } from '../../constants/imageUrlPath';

  export default {
    name: 'LoadingScreenUi',
    components: {
      PreloadStaticImages,
    },
    props: {
      navigateToSignup: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        progressValue: 0,
        loadingScreenElements: {
          loadingScreenLogo: staticImages['loadingScreenLogo'],
        },
      };
    },
    methods: {
      async onImageLoaded() {
        try {
          await preload(
            PIXI.Assets,
            percentage => (this.progressValue = percentage + 50),
          );
          this.$emit('assets-loaded');
          if (this.navigateToSignup) {
            this.$router.push('/login');
          }
        } catch (error) {
          console.error('Error preloading assets:', error);
        }
      },
      onImageLoadHandler(loadedImages, totalImages) {
        this.progressValue = (loadedImages / totalImages) * 50;
      },
    },
    computed: {
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
        };
      },
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
