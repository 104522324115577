export const brandConfigObjectMap = new Map();

brandConfigObjectMap.set('ais-tai-tha', {
    baseRoute : '/msisdn',
    productId : 33,
});

brandConfigObjectMap.set('mtn-ng-en', {
    baseRoute : '/login'
});
