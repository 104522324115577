<template>
  <div
    v-bind:style='backgroundImage'
    class='align-items-center d-flex flex-column collect-coins-main justify-content-center'
  >
    <div
      class='align-items-center insufficient-board-container d-flex flex-column justify-content-start w-75'
    >
      <img :src='singleCoin' class='w-25' style='margin-top: -1rem' />
      <div class='pb-5 pt-2 text-center' style='width: 80%' >
        <span class='fs-5'>  {{ translationsFormatter('coin_earned_text') }} </span> <br />
        <span class='fs-1'> 50 {{ translationsFormatter('coins_text') }} </span>
        <hr class='m-2 opacity-100'/>
        {{ translationsFormatter('coin_tomorrow_text') }}
      </div>
    </div>
    <button
      @click='navigateToConfirmPage'
      type='button'
      class='collect-coins-btn fs-4 text-light mt-5'
    >
      {{ translationsFormatter('coin_collect_text') }}
    </button>
  </div>
</template>

<script>
  import { staticImages, dynamicBg } from '../../constants/imageUrlPath';
  import { mapState } from 'vuex';

  export default {
    name: 'FirstLoginCoinCollectUi',
    data() {
      return {
        singleCoin: staticImages.singleCoin,
      };
    },
    methods: {
      navigateToConfirmPage() {
        if (this.user.username) {
          this.$router.push({ name: 'gameconfirm', query: { msisdn: this.user.msisdn } });
        } else {
          this.$router.push({ name: 'intro', query: { msisdn: this.user.msisdn } });
        }
      },
    },
    computed: {
      ...mapState(['user']),
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
          '--board-background': `url(${staticImages['board1']})`,
        };
      },
    },
  };
</script>

<style lang='css'>
  @import './index.css';
</style>
