<template>
  <div id="coin-collection-animation"></div>
</template>

<script>
  import { Config } from '../../game/Config';
  import * as PIXI from 'pixi.js';
  import { animationPaths } from '../../assetsPath';

  let animation, configInstance, app;

  export default {
    name: 'CoinCollectionAnimation',
    data() {
      return {};
    },

    async mounted() {
      app = new PIXI.Application({ backgroundAlpha: 0 });

      configInstance = new Config(true);

      app.view.width = configInstance.uiConstants.width;
      app.view.height = configInstance.uiConstants.height;
      document.getElementById('coin-collection-animation').appendChild(app.view);

      /**
       * @type Spritesheet
       */
      let spriteSheet = PIXI.Cache.get(animationPaths[11]);

      if (!spriteSheet) {
        spriteSheet = await PIXI.Assets.load(animationPaths[11]);
      }

      let searchingSpriteSheet = [];

      let searchingSpriteSheetImage = spriteSheet.data.animations;
      for (const character in searchingSpriteSheetImage) {
        const characterFileName = searchingSpriteSheetImage[character];

        for (const filename of characterFileName) {
          searchingSpriteSheet.push(filename);
        }
      }

      animation = PIXI.AnimatedSprite.fromFrames(searchingSpriteSheet);

      animation.position.x = 0;
      animation.position.y = 2;
      animation.loop = false;
      animation.width = configInstance.uiConstants.width;
      animation.height = configInstance.uiConstants.width;

      //  TODO : ask for animation speed
      animation.animationSpeed = 1 / 3;

      if (app.stage) {
        app.stage.addChild(animation);
        animation.play();
      }

      window.addEventListener('resize', this.onWindowResize);
    },
    unmounted() {
      window.removeEventListener('resize', this.onWindowResize);
      if (app) {
        app.destroy();
      }
    },
    components: {},
    methods: {
      onWindowResize() {
        configInstance = new Config(true);
        animation.width = configInstance.uiConstants.width;
        animation.height = configInstance.uiConstants.width;

        app.view.width = configInstance.uiConstants.width;
        app.view.height = configInstance.uiConstants.height;
      },
    },
  };
</script>
