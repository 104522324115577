import { createRouter, createWebHistory } from 'vue-router';
import LoadingScreen from '../components/LoadingScreenUi';
import SignUpScreen from '../components/SignUpScreenUi';
import HomeScreen from '../components/HomeScreenUi';
import LeaderboardUi from '../components/LeaderboardUi';
import RulesUi from '../components/RulesUi';
import GameConfirmationScreen from '../components/GameConfirmationScreenUi';
import Game from '../components/GameUi';
import IntroScreenUi from '../components/TutorialScreensUi/IntroScreenUi';
import FirstGameUi from '../components/TutorialScreensUi/FirstGameUi';
import SubmitUsernameUi from '../components/TutorialScreensUi/SubmitUsernameUi';
import PowerupSelection from '../components/PowerupSelectionUi';
import InsufficientFundsUi from '../components/InsufficientFundsUi';
import TopupFundsUi from '../components/TopupFundsUi';
import FirstLoginCoinCollectUi from '../components/FirstLoginCoinCollectUi';
import LoginErrorUi from '@/components/LoginErrorUi';
import TermsConditionsUi from '@/components/TermsConditionsUi';
import CoinCollectionUi from '../components/CoinCollectionUi';
import MsisdnInputUi from '@/components/MsisdnInputUi';
import { userAuthenticate } from '@/functions/userAuthenticate';
import { brandConfigMap } from '@/brand';

const routes = [
  {
    path: '/',
    redirect: getBaseRoute(),
  },
  {
    path: '/loading',
    component: LoadingScreen,
    name: 'loading',
  },
  {
    path: '/login',
    component: SignUpScreen,
    name: 'signup',
  },
  {
    path: '/msisdn',
    component: MsisdnInputUi,
    name: 'msisdn',
  },
  {
    path: '/error',
    component: LoginErrorUi,
    name: 'error',
  },
  {
    path: '/terms-and-conditions',
    component: TermsConditionsUi,
    name: 'termsConditions',
  },
  {
    path: '/home',
    name: 'home',
    component: HomeScreen,
    props: route => ({ phone: route.query.phone }),
  },
  { path: '/leaderboard', name: 'leaderboard', component: LeaderboardUi },
  { path: '/rules', name: 'rules', component: RulesUi },
  {
    path: '/collectcoins',
    name: 'collectcoins',
    component: FirstLoginCoinCollectUi,
    props: route => ({ phone: route.query.phone }),
  },
  {
    path: '/intro',
    name: 'intro',
    component: IntroScreenUi,
    props: route => ({ phone: route.query.phone }),
  },
  {
    path: '/firstgame',
    name: 'firstgame',
    component: FirstGameUi,
    props: route => ({ phone: route.query.phone }),
  },
  {
    path: '/submitusername',
    name: 'submitusername',
    component: SubmitUsernameUi,
    props: route => ({ phone: route.query.phone }),
  },
  {
    path: '/gameconfirm',
    name: 'gameconfirm',
    component: GameConfirmationScreen,
    props: route => ({ phone: route.query.phone }),
  },
  {
    path: '/game',
    name: 'game',
    component: Game,
    props: route => ({ phone: route.query.phone }),
  },
  {
    path: '/selectpowerups',
    name: 'selectpowerups',
    component: PowerupSelection,
    props: route => ({ phone: route.query.phone }),
  },
  {
    path: '/insufficientfunds',
    name: 'insufficientfunds',
    component: InsufficientFundsUi,
    props: route => ({ phone: route.query.phone }),
  },
  {
    path: '/topupfunds',
    name: 'topupfunds',
    component: TopupFundsUi,
    props: route => ({ phone: route.query.phone }),
  },
  {
    path: '/coincollection',
    name: 'coincollection',
    component: CoinCollectionUi,
    props: route => ({ phone: route.query.phone }),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  await userAuthenticate(to, from, next);
});

function getBaseRoute(){
  const domain = window.origin;
  const brandConfig = brandConfigMap.get(domain) ? brandConfigMap.get(domain) : brandConfigMap.get('default');
  return brandConfig.baseRoute;
}

export default router;
