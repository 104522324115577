import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class AisService {
    static async getUserViaProductId(productId, msisdn) {
        const response = await axios.post(`${environmentConfig.upstreamAisHost}/user/get-user-via-product-id`, {
            productId: productId,
            msisdn: msisdn,
        })
        return response.data;
    }

    static async trackAisUserLogin(productId, msisdn) {
        return await axios.post(`${environmentConfig.upstreamAisHost}/user/track-user-login`, {
            productId: productId,
            msisdn: msisdn,
            isWeb: true,
        })
    }
}
