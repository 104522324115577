<template>
  <div
    v-bind:style='backgroundImage'
    class='align-items-center coin-collection-main d-flex flex-column justify-content-around text-shadow'
  >
    <div class='align-items-center d-flex flex-column position-relative'>
      <span class='fs-1 position-absolute' style='top: 2%'> {{ translationsFormatter('opponent_found_text') }}</span>
      <CoinCollectionAnimation />
      <span class='fs-1 position-absolute top-50'
      ><number
        ref='number1'
        :from='0'
        :to='20'
        :format='theFormat'
        :duration='1'
        :delay='0.5'
        easing='Bounce.easeInOut'
      />
        {{ translationsFormatter('coins_text') }}</span
      >

      <div class='d-flex w-100 position-absolute' style='top: 90%'>
        <div class='align-items-center d-flex flex-column w-50'>
          <img
            :src="PlayerIconBlue[bluePlayerAvatar] || PlayerIconBlue['armadillo']"
            class='width-65'
          />
          <span class='fs-1'>{{ translationsFormatter('you_text') }}</span>
        </div>
        <div class='align-items-center d-flex flex-column w-50'>
          <img :src="PlayerIconRed['armadillo']" class='width-65' />
          <span class='fs-1'>{{ redPlayerUsername }}</span>
        </div>
      </div>
    </div>
    <div class='d-flex flex-column text-center fs-1 lh-1'>
      {{ translationsFormatter('match_begin_text') }}<br />
      3...2...1...
    </div>
  </div>
</template>

<script>
  import { dynamicBg, PlayerIconBlue, PlayerIconRed } from '../../constants/imageUrlPath';
  import CoinCollectionAnimation from '../CoinCollectionAnimation';

  export default {
    name: 'HomeScreenUi',
    components: {
      CoinCollectionAnimation,
    },
    data() {
      return {
        PlayerIconBlue: PlayerIconBlue,
        PlayerIconRed: PlayerIconRed,
        bluePlayerAvatar: '',
        redPlayerAvatar: '',
        redPlayerUsername: 'user359',
      };
    },
    methods: {},
    computed: {
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
        };
      },
    },
    mounted() {
      const data = JSON.parse(localStorage.getItem('userData'));
      this.bluePlayerAvatar = data?.avatar;
    },
  };
</script>

<style lang='css'>
  @import './index.css';
</style>
