import { createStore } from 'vuex';

export default createStore({
  state: {
    msisdn: undefined,
    user: undefined,
    brandConfig: undefined,
  },
  mutations: {
    setMsisdn(state, msisdn) {
      state.msisdn = msisdn;
    },
    setUser(state, user) {
      state.user = user
    },
    setBrandConfig(state, brandConfig) {
      state.brandConfig = brandConfig
    },
  },
  actions: {},
  modules: {},
});
