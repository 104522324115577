<template>
  <div
    v-bind:style="backgroundImage"
    class="align-items-center d-flex flex-column top-up-main justify-content-center"
  >
    <div
      class="align-items-center top-up-board-container d-flex flex-column justify-content-start w-75"
    >
      <img :src="coinBag" class="coin-bag" />
      <div class="pb-5 pt-2 text-center">
        <span class="fs-1">   {{ translationsFormatter('top_funds_header') }}<br /></span>
        <hr class="m-2 opacity-50" />
        <ul class="list list-unstyled">
          <li
            v-for="item in items"
            :key="item.id"
            @click="handleItemClick(item.id)"
            :class="{ selected: selectedItem === item.id }"
            class="p-2 rounded-3"
          >
            {{ item.name }}
          </li>
        </ul>
        <hr class="m-2 opacity-50"/>
        <div class='d-flex flex-column align-items-center'>
          <div style='width: 80%;'>
            {{ translationsFormatter('select_bundle_text') }}
          </div>
        </div>
      </div>
    </div>
    <button
      @click="navigateToPurchase"
      type="button"
      class="purchase-btn fs-4 text-light mt-5"
    >
      {{ translationsFormatter('purchase_button') }}
    </button>
    <button @click="back" type="button" class="back-btn fs-4 text-light mt-3">
      {{ translationsFormatter('back_button') }}
    </button>
  </div>
</template>

<script>
  import { staticImages, dynamicBg } from '../../constants/imageUrlPath';
  import { topupFundsData } from '../../constants/dummyData';

  export default {
    name: 'TopupFundsUi',
    data() {
      return {
        coinBag: staticImages.coinBag,
        items: topupFundsData,
        selectedItem: null,
      };
    },
    methods: {
      navigateToPurchase() {},
      back() {
        this.$router.go(-1);
      },
      handleItemClick(itemId) {
        this.selectedItem = itemId;
        console.log('Clicked item with id: ' + itemId);
      },
    },
    computed: {
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
          '--board-background': `url(${staticImages['board3']})`,
        };
      },
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
