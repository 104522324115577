<template>
  <div v-bind:style='backgroundImage' class='signup-main'>
    <div :class="[textColor, 'fs-5', 'pe-2', 'ps-2', 'text-outline', 'mt-1']">
      <div class='d-flex flex-column text-center header-text'>Terms & Conditions</div>
      <div class='d-flex flex-column terms-conditions-container'>
        <div class='col-12'>
          <div class='mt-2'>SUBSCRIPTION SERVICE: NGN20/DAY</div>
          <div class='mt-2'>NOTICE: The ng.megamatchrival.mobi service is provided subject to these terms and conditions which explain how you may access and use the service and the Privacy Policy,
            ‘http://o-play.com/privacy-policy/’ which explains how we process and use your personal information (The “Agreement”).
          </div>
          <div class='mt-2'>
            IF YOU ARE UNDER 18 OR DO NOT CONSENT TO THIS AGREEMENT OR IF YOU ARE NOT WILLING TO BE BOUND BY IT THEN DO NOT ACCESS THE SERVICE OR PROMPTLY CEASE USING THE SERVICE ON YOUR MOBILE
            DEVICE.
          </div>
          <div class='mt-2'>
            MEGA MATCH RIVALS CONTENT AND PRICING
          </div>
          <div class='mt-2'>
            ng.megamatchrival.mobi is a mobile subscription service charged at NGN20 per day which offers you unlimited access to the content available while you remain subscribed (the “Service”). The
            daily charge will be recovered directly by your network service provider and you will in turn pay your network service provider. You authorize us to recover the relevant amounts directly
            from your network service provider who you authorize to pay us. You confirm that you are allowed to provide the authorizations mentioned above. Network charges may also apply.
          </div>
          <div class='mt-2'>
            ng.megamatchrival.mobi has some of the hottest mobile content available plus an extensive amount of new content and categories added on a regular basis (the “Content”). Being a member of
            ng.megamatchrival.mobi gives you access to this wide and varied selection of mobile content.
          </div>
          <div class='mt-2'>
            MARKETING COMMUNICATIONS
          </div>
          <div class='mt-2'>
            By entering into this Agreement, you consent to receive future marketing communications from us. You can opt-out of receiving these communications at any time by following the opt-out
            instructions below or in each message sent to you.
          </div>
          <div class='mt-2'>
            CANCELLATION
          </div>
          <div class='mt-2'>
            You are free to cancel your subscription at any time by following the instructions below:
          </div>
          <div class='mt-2'>
            Complete the process on this page: http://ng.megamatchrival.mobi/unsubscribe/
          </div>
          <div class='mt-2'>
            1. INTRODUCTION
          </div>
          <div class='mt-2'>
            The Service is offered by O’Play Digital Services Limited, which is a limited liability company registered in the Federal Republic of Nigeria under company number RC 1420505, trading here
            as ‘ng.megamatchrival.mobi’ (the “Company“, “we”, “us”, “our”).
          </div>
          <div class='mt-2'>
            1. EFFECT OF THIS AGREEMENT
          </div>
          <div class='mt-2'>
            2.1 By consenting to this Agreement and/or by continuing to use the Service you are bound by the entirety of this Agreement and as such terms may be amended by us from time to time. In the
            case of any inconsistency between any specific rules and this Agreement, this Agreement shall prevail.
          </div>
          <div class='mt-2'>
            2.2 We reserve the right to make any amendments to this Agreement, as we deem necessary, in our sole discretion. By continuing to use the Service you are accepting any changes to this
            Agreement.
          </div>
          <div class='mt-2'>
            1. YOUR REPRESENTATIONS
          </div>
          <div class='mt-2'>
            3.1 By seeking to register with us or by using the Service you hereby represent and warrant to us that at all such times you:
          </div>
          <div class='mt-2'>
            1. a) are located in Nigeria;<br>
            2. b) are aged 18 years or over;<br>
            3. c) are of sound mind and capable of taking responsibility for your own actions;<br>
            4. d) can enter into a legally binding agreement and you are the person who has registered with us;<br>
            5. e) understand that you may be charged for using the Service and that you accept full responsibility for any such charges that may apply;<br>
            6. f) are acting as principal and not on behalf of anyone else;<br>
            7. g) are the authorized owner of the mobile device which you registered to the Service during the registration process or any subsequent mobile device registered on the Service.<br>
          </div>
          <div class='mt-2'>
            3.2 You agree to abide in full with this Agreement.
          </div>
          <div class='mt-2'>
            3.3 You may only access the Content and/or use the Service by accessing:
          </div>
          <div class='mt-1'>
            ng.megamatchrival.mobi a (the “Website”). You are not allowed to access the Service through any other medium.
          </div>
          <div class='mt-2'>
            3.4 You hereby warrant to us that: a) all information provided in your registration to the Service and all personal information provided to us is complete, accurate and not misleading; b)
            you will only use the Service strictly for legitimate purposes only; and c) you will not attempt to hack, make unauthorized alterations to or introduce any kind of malicious code to the
            Website, the Service or the Company by any means. Charges and proceedings may be brought against you if you try to manipulate the Service or any of the Content and we reserve the right to
            pass on such information as we deem necessary to the relevant authorities if we become aware or suspect that you are involved in any such activities. You agree to any such disclosure.
          </div>
          <div class='mt-2'>
            1. UPDATES
          </div>
          <div class='mt-2'>
            We may change at our sole discretion in whole or in part the format of the Service or the Content that we offer in order to enhance, correct or support such Services or Content or for any
            other reason.
          </div>
          <div class='mt-2'>
            1. REGISTRATION
          </div>
          <div class='mt-2'>
            5.1 Before you are able to access any of the Content or use the Service, you will be required to register with us by completing the registration process on the Website.
          </div>
          <div class='mt-2'>
            5.2 Following registration you will be provided with access to the Service, upon being successfully billed by us via the mobile phone number that you registered with. Transactions made
            using your mobile number are accepted by us strictly on the understanding that you are using the Service. You agree to be solely responsible for use of the Service through use of your
            mobile device at all times. If an alternative source has accessed your mobile number we accept no liability whatsoever including but not limited to any charges, information lost, stolen or
            misused. If you believe that your mobile number is in any way being misused by a third party please inform us immediately so that we may suspend the Service.
          </div>
          <div class='mt-2'>
            5.3 We reserve the right to verify your identity at any time (including by using third parties, which may keep a record of that information). We reserve the right to conduct checks against
            any of the details provided by you to us in your registration. If upon our request you fail to provide the requested information, this will result in the suspension of your registration
            and/or the Service.
          </div>
          <div class='mt-2'>
            5.4 Upon completion of the registration we will confirm by SMS to the mobile number which you provided that your registration is now complete. This SMS may also be sent by your mobile
            network operator.
          </div>
          <div class='mt-2'>
            5.5 We reserve the right to monitor the use of the Service and we may elect, in our sole discretion to suspend and/or terminate the registration if we consider or suspect that the Service
            or any parts thereof is being used in breach of this Agreement or for any other reason that we deem necessary.
          </div>
          <div class='mt-2'>
            5.6 You agree to return in the same condition or forthwith settle to us the cash equivalent (if applicable, and calculated as at the date(s) of the prize(s) being awarded) any and all
            prizes which may have been awarded to you under the Service, in circumstances where we subsequently discover that you have breached this Agreement (including without limitation where the
            mobile number used to register to the Service, the use of the Service and/or the registration itself is disputed).
          </div>
          <div class='mt-2'>
            5.7 We reserve the right to record all telephone calls made to us and to monitor all information relating to the Service for which purposes you consent including forwarding on such calls
            to our authorized third parties.
          </div>
          <div class='mt-2'>
            1. COMPATIBILITY
          </div>
          <div class='mt-2'>
            6.1. You may experience problems or be unable to access or download certain Content successfully depending on the mobile device used to access or download certain Content onto. Access via
            streaming will be available for all smart devices. For feature devices which are not compatible with streaming, downloads will be available. Non-android smart phones (such as iOS devices
            and Windows devices) and possibly some Android phones will only be able to access limited Content due to the type of Operating systems on the mobile devices. This is an issue with the
            third party technology used in the provision of the Content which is not available on all handsets and out of the Company’s control. If you have this type of issue that limits your access
            to some or all of the Content and would like to raise a query or ask for technical assistance, please email our customer support team at support@mobilecontentafrica.com. You acknowledge
            that we are not responsible for any costs or losses incurred by you, damage to your mobile device or loss of data resulting from any such incompatibility.
          </div>
          <div class='mt-2'>
            6.2 We make no representation as to the compatibility of your mobile device with the Service and you acknowledge and agree that the Company shall have no liability for the compatibility or
            non-compatibility of your mobile handset with the Service and/or the Content.
          </div>
          <div class='mt-2'>
            1. SECURITY POLICY
          </div>
          <div class='mt-2'>
            We will not sell your personal detail to third parties. However, we may pass on your details to relevant authorities or regulators if we wish to investigate any suspected or alleged
            illegal activity, fraud, harassment, or abuse of the Service in any manner or to assist in the investigation of any suspected or illegal activity, fraud or abuse of the Service or if we
            are required by law to do so.
          </div>
          <div class='mt-2'>
            1. REWARDS
          </div>
          <div class='mt-2'>
            8.1 We may offer from time to time Content which is free to access but which will require full registration.
          </div>
          <div class='mt-2'>
            8.2 The rules of access to free Content will be displayed on the Website and may be accessible to non-registered members.
          </div>
          <div class='mt-2'>
            1. COMPLAINTS AND DISPUTES
          </div>
          <div class='mt-2'>
            9.1 Should you wish to make a complaint to us in respect of the Service or other matter, please email support@mobilecontentafrica.com. We will endeavor to assist you as soon as reasonably
            possible.
          </div>
          <div class='mt-2'>
            1. OUR LIABILITY
          </div>
          <div class='mt-2'>
            10.1 The following provisions set out the entire financial liability of the Company (including any liability for acts or omissions of its parent company, subsidiaries, associated
            companies, or suppliers), and their respective employees, agents and sub-contractors) to you in respect of: a) any breach of this Agreement, including any deliberate personal repudiatory
            breach or any deliberate breach of these conditions by a party, or its employees, agents or subcontractors; and b) any representation, statement and/or tortuous act or omission including
            negligence arising under or in connection with the Service.
          </div>
          <div class='mt-2'>
            10.2 Nothing in these terms and conditions excludes or limits our liability: a) for death or personal injury caused by the Company’s negligence; or b) for any matter which it would be
            illegal for the Company to exclude or attempt to exclude its liability for; or c) for fraud or fraudulent misrepresentation.
          </div>
          <div class='mt-2'>
            10.3 We are not liable for any loss or damage that you may suffer because of any act of God, power cut, trade or labour dispute, failure or any omission of any government or authority;
            obstruction or failure of telecommunication services or any other delay or failure caused by a third party or which is outside of our control. In such an event, we reserve the right to
            cancel or suspend the Services in whole or part without incurring any liability.
          </div>
          <div class='mt-2'>
            10.4 All representations, warranties and terms (whether express or implied) not set out in this Agreement are, to the fullest extent permitted by law, excluded and we shall, to the fullest
            extent permitted by law, have no liability to you in respect of the same. You agree to indemnify us in respect of any liability, damages, costs or claims (save to the extent the same arise
            out of or in connection with the Company’s breach of contract or its negligence) which we may suffer arising out of or in connection with your use of the Service or otherwise arising out
            of or in connection with our services. Your statutory rights as a consumer (if any) are not affected by this Agreement.
          </div>
          <div class='mt-2'>
            10.5 Subject to Clause 10.2, a) our liability to you in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in
            connection with the performance or contemplated performance of the Service shall be limited to the aggregate of the sum paid for the Service in question for the preceding 12 (twelve)
            months; b) we shall not be liable to you for any direct, indirect or consequential loss (including without limitation, loss of profit, loss of goodwill, loss of amenity and loss of
            contract) or any claims for consequential compensation whatsoever (howsoever caused) which arise out of or in connection with the Services whether or not we have been made aware of it; c)
            we shall not be held liable or responsible for any consequences that occur through your use of the Service where the circumstances that caused such consequences were beyond our reasonable
            control, including any loss or damage that has arisen through the Website, the Content or the Service or its content, including delays or interruptions in operation or transmission, loss
            or corruption of data, any person’s misuse of the Service or any error or omission in content.
          </div>
          <div class='mt-2'>
            10.6 We accept no responsibility and shall not be liable to you for the content of or use by you of any information or services offered by third parties’ advertising (including advertising
            by any referral companies) or otherwise posting information via the Website (whether directly or via links to or from other sites or resources or through framing or other electronic
            mechanisms), nor can we be said to endorse the contents of such advertisements or information. In particular, we shall have no liability in respect of material hyper-linked to its web
            pages which may be misleading, inaccurate, defamatory, threatening or obscene or otherwise not in accordance with applicable laws or regulations. The provision by us on the Website of a
            link to another website does not constitute any authorization to access materials, nor any accreditation of any such materials held at that location.
          </div>
          <div class='mt-2'>
            10.7 We make no representation or warranty about the information or any other items able to be accessed either directly or indirectly via the Website and/or Service (save to the extent
            expressly provided on the Website or Service) and we reserve the right to make changes and corrections at any time to such information, without notice. We accept no liability for any
            inaccuracies or omissions (other than a fraudulent misrepresentation) in or from such information and any decisions based on such information are the sole responsibility of the visitor to
            the Website.
          </div>
          <div class='mt-2'>
            10.8 We are not liable for any failure to perform by a third party to this Agreement.
          </div>
          <div class='mt-2'>
            1. INTELLECTUAL PROPERTY
          </div>
          <div class='mt-2'>
            11.1 The copyright, database rights and other intellectual property rights (“IPR”) in material displayed on or via the Service (the “Materials“, which expression includes text, data,
            graphics, photographs, videos, animation, images and audio visual content, are owned by or licensed to us or are the ownership of third party websites). The IPR are protected by the laws
            of Nigeria, international treaties and all other applicable copyright and intellectual property rights laws. You are not authorized to copy or distribute any Materials and/or IPR and legal
            action could be taken against you or any such person who makes unauthorized copies or distribution of Materials and/or IPR.
          </div>
          <div class='mt-2'>
            11.2 Any downloading, use or copying of the Materials is strictly prohibited and, in particular, you agree to use the Materials solely for your own personal, non-commercial use and
            specifically not for any business, commercial or public purposes.
          </div>
          <div class='mt-2'>
            11.3 The following activities are prohibited without our express prior written permission: a) the deployment within the Website of any spider, robot, web crawler or other automated query
            program; and b) the re-use and/or aggregation of any of the Materials in the provision of a commercial service.
          </div>
          <div class='mt-2'>
            11.4 The copying and use of third party Materials accessed via the Website is governed by the terms of use applicable to the third party website accessed by you.
          </div>
          <div class='mt-2'>
            11.5 Our names and associated logos are our exclusive trademarks and cannot be used by you without our prior written permission.
          </div>
          <div class='mt-2'>
            1. SUBMISSION OF INFORMATION
          </div>
          <div class='mt-2'>
            Save for personal information (which shall be dealt with in accordance with the Privacy Policy), all information (including but not limited to ideas, suggestions, concepts and graphics)
            submitted to us or other users through the Service will become our exclusive property and we shall not be subject to any obligation of confidentiality and be free to use such information
            for any purpose without any restriction or consideration whatsoever.
          </div>
          <div class='mt-2'>
            1. GENERAL
          </div>
          <div class='mt-2'>
            13.1 This Agreement constitutes to the fullest extent permitted by law the whole of the Agreement between you and us with regard to the use of the Website, Content and the Service.
          </div>
          <div class='mt-2'>
            13.2 If any part of this Agreement is found by a court of competent jurisdiction or other competent authority to be invalid, unlawful or unenforceable then any such part will be severed
            from the remainder of this Agreement, which will continue to be valid and enforceable to the fullest extent permitted by law.
          </div>
          <div class='mt-2'>
            13.3 No failure or delay by us to exercise any of our rights under this Agreement shall operate as a waiver thereof and no single or partial exercise of any such right shall prevent any
            other or further exercise of that or any other right by us.
          </div>
          <div class='mt-2'>
            1. GOVERNING LAW AND DISPUTES
          </div>
          <div class='mt-2'>
            This Agreement shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria. You irrevocably agree that the courts of the Federal Republic of Nigeria
            shall have exclusive jurisdiction to resolve any dispute or claim of whatever nature arising out of or relating to the Website and the Service, and that the laws of the Federal Republic of
            Nigeria shall govern any such dispute or claim. However, we retain the right to bring legal proceedings in any jurisdiction where we believe that infringement of our intellectual property
            rights or breach of this Agreement is taking place or originating. You are responsible for compliance with any applicable laws and regulations of the jurisdiction from which you are
            accessing or using the Website, the Service, its Contents whether in whole or part.
          </div>
          <div class='mt-2'>
            Customer Service
          </div>
          <div class='mt-2'>
            Email: support@mobilecontentafrica.com
          </div>
        </div>
      </div>
    </div>
    <SpinnerUi v-if='showSpinner' />
  </div>
</template>

<script>
  import SpinnerUi from '../SpinnerUi';
  import { dynamicBg } from '../../constants/imageUrlPath';

  export default {
    name: 'SignUpScreenUi',
    components: {
      SpinnerUi,
    },
    data() {
      return {
        showSpinner: false,
        textColor: 'text-black',
      };
    },
    methods: {},
    computed: {
      backgroundImage() {
        return {
          '--background-image': `url(${dynamicBg[0]})`,
        };
      },
    },
    mounted() {
      if (this.$DYNAMIC_BG == 'nigeria1' || this.$DYNAMIC_BG == 'nigeria3')
        this.textColor = 'text-white';
    },
  };
</script>

<style lang='css'>
  @import './index.css';

  .header-text {
    font-size: 30px;
  }

  .terms-conditions-container {
    font-size: 15px;
    height: 80vh;
    overflow: auto;
  }
</style>
