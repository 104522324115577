<template>
  <div
    :class="['progress progressGame', direction]"
    :style="{
      height: `100%`,
      background: background,
    }"
  >
    <div
      :class="['progress-bar', color]"
      role="progressbar"
      :style="{ width: `${progressVal}%` }"
      :aria-valuenow="progressVal"
      aria-valuemin="0"
      aria-valuemax="100"
    />
    <span class="label">{{ labelVal }}</span>
  </div>
</template>

<script setup>
  import { defineProps } from 'vue';

  defineProps({
    progressVal: Number,
    direction: String,
    labelVal: String,
    height: Number,
    color: String,
    background: String,
  });
</script>

<script>
  export default {
    name: 'ProgressBar',
  };
</script>

<style lang="css">
  @import './index.css';
</style>
