import dotenv from 'dotenv';

dotenv.config();

export const productionConfig = {
  upstreamAisHost: `https://upstream-ais.penrosegaming.co.za`,
};

export const stagingConfig = {
  upstreamAisHost: `https://upstream-ais.penrosegaming.co.za`,
};

const environment = getEnvironment();

export const environmentConfig = getEnvObject();

function getEnvironment() {
  let base_url = window.location.origin;
  if (base_url.includes('staging')) return 'staging';
  if (base_url.includes('localhost')) return 'development';
  else return 'production';
}

function getEnvObject() {
  if (environment === 'staging') return stagingConfig;
  if (environment === 'development') return productionConfig;
  if (environment === 'production') return productionConfig;
}
